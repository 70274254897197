import TextField from "./TextField";
import * as Yup from "yup";
import RadioGroupField from "./RadioGroupField";
import { Connectors, Evse } from "types/ChargerTemplate";
import { Typography } from "@mui/material";

export enum fieldNames {
  vendorId = "vendorId",
  vendorModel = "vendorModel",
  vendorName = "vendorName",
  images = "images",
  evses = "evses",
  connectors = "connectors",
  parameterSet = "parameterSet",
}

export enum evseFieldNames {
  currentType = "currentType",
  maxPower = "maxPower",
  maxVoltage = "maxVoltage",
  maxAmperage = "maxAmperage",
  physicalReference = "physicalReference",
  numberOfPhases = "numberOfPhases",
}

export enum connectorFieldNames {
  type = "type",
  evseId = "evseId",
  maxPower = "maxPower",
  maxVoltage = "maxVoltage",
  maxAmperage = "maxAmperage",
  physicalReference = "physicalReference",
  numberOfPhases = "numberOfPhases",
}

export interface FieldValueTypes {
  [fieldNames.vendorId]: string;
  [fieldNames.vendorModel]: string;
  [fieldNames.vendorName]: string;
  [fieldNames.evses]: Evse[];
  [fieldNames.connectors]: Connectors[];
  [fieldNames.parameterSet]: any;
}

const commonProps = {
  color: "field",
  variant: "outlined",
};

const fields = [
  {
    name: fieldNames.vendorId,
    placeholder: "Enter Vendor ID",
    component: TextField,
    label: "Vendor ID",
    colCount: 6,
    ...commonProps,
  },
  {
    name: fieldNames.vendorModel,
    placeholder: "Enter Vendor Model",
    component: TextField,
    label: "Vendor Model",
    colCount: 6,
    ...commonProps,
  },
  {
    name: fieldNames.vendorName,
    placeholder: "Enter Vendor Name",
    component: TextField,
    label: "Vendor Name",
    colCount: 12,
    ...commonProps,
  },
];

const evseField = [
  {
    name: evseFieldNames.currentType,
    placeholder: "Enter current type",
    component: RadioGroupField,
    label: "Current type",
    options: [
      { label: "DC", value: "DC" },
      { label: "AC", value: "AC" },
    ],
    radioGroup: true,
    colCount: 2.5,
    ...commonProps,
  },
  {
    name: evseFieldNames.maxPower,
    placeholder: "Enter maximum power",
    component: TextField,
    label: "Max power",
    colCount: 3,
    ...commonProps,
    suffix: <Typography>Kw</Typography>,
    type: "number",
  },
  {
    name: evseFieldNames.maxVoltage,
    placeholder: "Enter maximum voltage",
    component: TextField,
    ...commonProps,
    label: "Max voltage",
    colCount: 3,
    suffix: <Typography>V</Typography>,
    type: "number",
  },
  {
    name: evseFieldNames.maxAmperage,
    placeholder: "Enter maximum ampere",
    component: TextField,
    label: "Max Ampere",
    colCount: 2.5,
    ...commonProps,
    suffix: <Typography>A</Typography>,
    type: "number",
  },
  {
    name: evseFieldNames.physicalReference,
    placeholder: "Enter Physical refrence",
    component: TextField,
    label: "Physical Refrence",
    colCount: 6,
    ...commonProps,
  },
  {
    name: evseFieldNames.numberOfPhases,
    placeholder: "Enter Number of Phases",
    component: TextField,
    label: "Number of Phases",
    colCount: 5,
    ...commonProps,
  },
];

export const connectorsField = [
  {
    name: connectorFieldNames.type,
    placeholder: "Enter type",
    component: TextField,
    label: "connector type",
    colCount: 3,
    ...commonProps,
    select: true,
  },
  {
    name: connectorFieldNames.evseId,
    placeholder: "Enter evse id",
    component: TextField,
    ...commonProps,
    label: "Evse id",
    select: true,
    colCount: 2,
  },
  {
    name: connectorFieldNames.maxPower,
    placeholder: "Enter maximum power",
    component: TextField,
    label: "Max power",
    colCount: 3,
    ...commonProps,
    suffix: <Typography>Kw</Typography>,
    type: "number",
  },
  {
    name: connectorFieldNames.maxVoltage,
    placeholder: "Enter maximum voltage",
    component: TextField,
    ...commonProps,
    label: "Max voltage",
    colCount: 3,
    suffix: <Typography>V</Typography>,
    type: "number",
  },
  {
    name: connectorFieldNames.maxAmperage,
    placeholder: "Enter maximum ampere",
    component: TextField,
    label: "Max Ampere",
    colCount: 3,
    ...commonProps,
    suffix: <Typography>A</Typography>,
    type: "number",
  },
  {
    name: connectorFieldNames.physicalReference,
    placeholder: "Enter Physical refrence",
    component: TextField,
    label: "Physical Refrence",
    colCount: 4,
    ...commonProps,
  },
  {
    name: connectorFieldNames.numberOfPhases,
    placeholder: "Enter Number of Phases",
    component: TextField,
    label: "Number of Phases",
    colCount: 4,
    ...commonProps,
  },
];

export const parameterSetField = [
  {
    name: fieldNames.parameterSet,
    placeholder: "jasjkhk",
    label: "ParameterSet",
    component: TextField,
    ...commonProps,
    colCount: 12,
    maxRows: 12,
    multiline: true,
  },
];

const parameterSetInitialValue = "";

const evseInitialValues: Evse[] = [
  {
    id: 1,
    [evseFieldNames.currentType]: "",
    [evseFieldNames.maxPower]: 0,
    [evseFieldNames.maxVoltage]: 0,
    [evseFieldNames.maxAmperage]: 0,
    [evseFieldNames.physicalReference]: "",
    [evseFieldNames.numberOfPhases]: 0,
  },
];

const connectorsInitialValue: Connectors[] = [
  {
    id: 1,
    [connectorFieldNames.type]: "",
    [connectorFieldNames.evseId]: 1,
    [connectorFieldNames.physicalReference]: "",
    [connectorFieldNames.numberOfPhases]: 0,
    [connectorFieldNames.maxPower]: 0,
    [connectorFieldNames.maxVoltage]: 0,
    [connectorFieldNames.maxAmperage]: 0,
  },
];

const initialValues: FieldValueTypes = {
  [fieldNames.vendorId]: "",
  [fieldNames.vendorName]: "",
  [fieldNames.vendorModel]: "",
  [fieldNames.evses]: evseInitialValues,
  [fieldNames.connectors]: connectorsInitialValue,
  [fieldNames.parameterSet]: parameterSetInitialValue,
};

const validationSchema = [
  Yup.object({
    [fieldNames.vendorId]: Yup.string().required("Vendor Id is required field"),
    [fieldNames.vendorName]: Yup.string(),
    [fieldNames.vendorModel]: Yup.string().required(
      "Vendor Model is required field"
    ),
  }),
  Yup.object({
    [fieldNames.evses]: Yup.array().of(
      Yup.object().shape({
        [evseFieldNames.currentType]: Yup.string().required(
          "Cuurent Type is required field"
        ),
        [evseFieldNames.maxAmperage]: Yup.number()
          .min(1, "Ampere must be greater than 0")
          .required("Ampere is is required field"),
        [evseFieldNames.maxVoltage]: Yup.number()
          .min(1, "Voltage must be greater than 0")
          .required("Voltage is is required field"),
        [evseFieldNames.maxPower]: Yup.number()
          .min(1, "Power must be greater than 0")
          .required("Max Power is is required field"),
      })
    ),
    [fieldNames.connectors]: Yup.array().of(
      Yup.object().shape({
        [connectorFieldNames.type]: Yup.string().required(
          "Connector type is required field"
        ),
        [connectorFieldNames.evseId]: Yup.number().required(
          "Evse Id id required field"
        ),
      })
    ),
  }),
];

export {
  fields,
  initialValues,
  evseInitialValues,
  connectorsInitialValue,
  validationSchema,
  evseField,
};
