import { IonButton, IonIconButton } from "@components/ionButton";
import {
  AlertColor,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Slide,
  Step,
  StepButton,
  StepConnector,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/delete.svg";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import { ReactComponent as LeftIcon } from "@assets/icons/chev-left-icon.svg";

import {
  FieldValueTypes,
  evseField,
  fieldNames,
  fields,
  initialValues,
  validationSchema,
  connectorsField,
  parameterSetField,
  connectorFieldNames,
} from "./fields";
import {
  getChargerTemplate,
  postChargerTemplate,
} from "@nexusAPI/chargerTemplates";
import {
  Field,
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikValues,
} from "formik";
import IonSnackbar from "@components/ionSnackbar";
import { getErrorMessage } from "@utils/APIInterceptor";
import { getChargingStationsConnector } from "@nexusAPI/connector-types";

interface ModalProps {
  modalOpen: boolean;
  handleClose: (event?: any) => void;
  vendorId?: string;
  vendorModel?: string;
  onChargerTemplateAddCallback?: any;
}
const MODAL_SPACING = 64;
const HEADER_HEIGHT = 66;

const steps = ["Add Details", "Add Evse and Connectors", "Add ParamterSet"];

const ChargerTemplateModal = forwardRef(
  (
    {
      modalOpen,
      handleClose,
      vendorId,
      vendorModel,
      onChargerTemplateAddCallback,
    }: ModalProps,
    ref
  ) => {
    const theme = useTheme();
    const [snackbar, setSnackbar] = useState<{
      message: string;
      severity?: AlertColor;
    }>({
      message: "",
      severity: undefined,
    });
    const [activeStep, setActiveStep] = useState<number>(0);
    const handleStep = (step: number) => () => {
      setActiveStep(step);
    };

    const handleBack = () => {
      setActiveStep((prev) => prev - 1);
    };

    const [apiInitialValues, setApiInitialValues] =
      useState<FieldValueTypes>(initialValues);
    const [allConnectors, setAllConnectors] = useState<
      Array<{ label: string; value: string }>
    >([]);

    const getAllConnectors = useCallback(async () => {
      try {
        const data = await getChargingStationsConnector();
        setAllConnectors(
          data.map((connector) => ({
            label: connector.display_text,
            value: connector.type,
          }))
        );
      } catch {}
    }, []);

    const getChargerTemplateDetails = useCallback(
      async (vendorId: string, vendorModel: string) => {
        try {
          const data = await getChargerTemplate({ vendorId, vendorModel });
          const chargerTemplatesValues: FieldValueTypes = {
            [fieldNames.vendorId]: data.vendorId || "",
            [fieldNames.vendorName]: data.vendorName || "",
            [fieldNames.vendorModel]: data.vendorModel || "",
            [fieldNames.evses]: data.evses.map((evse) => evse) || [],
            [fieldNames.connectors]:
              data.connectors.map((connector) => connector) || [],
            [fieldNames.parameterSet]:
              JSON.stringify(
                data.parameterSet.map((each) => each),
                null,
                2
              ) || [],
          };
          setApiInitialValues(chargerTemplatesValues);
        } catch (err) {
          const chargerTemplatesValues: FieldValueTypes = {
            [fieldNames.vendorId]: vendorId,
            [fieldNames.vendorName]: "random Name",
            [fieldNames.vendorModel]: vendorModel,
            [fieldNames.evses]: [],
            [fieldNames.connectors]: [],
            [fieldNames.parameterSet]: undefined,
          };
          setApiInitialValues(chargerTemplatesValues);
        }
      },
      []
    );

    useEffect(() => {
      if (vendorId && vendorModel) {
        getChargerTemplateDetails(vendorId, vendorModel);
      }
      getAllConnectors();
    }, [vendorId, vendorModel, getChargerTemplateDetails, getAllConnectors]);

    const createChargerTemplate = useCallback(
      async (values: FieldValueTypes, id?: string, model?: string) => {
        const {
          vendorId,
          vendorName,
          vendorModel,
          connectors,
          evses,
          parameterSet,
        } = values;
        try {
          const response = await postChargerTemplate({
            vendorId,
            vendorName,
            vendorModel,
            connectors,
            evses,
            parameterSet,
          });
          if (!response) {
            throw new Error(
              "error occured while creating new charger template"
            );
          }
          setSnackbar({
            message: `Charger template ${vendorId && vendorModel ? "updated" : "added"} successfully`,
            severity: "success",
          });
          const sto = setTimeout(() => {
            onChargerTemplateAddCallback();
          }, 3000);
          return () => clearTimeout(sto);
        } catch (err: any) {
          const message = getErrorMessage(err, "createTemplateCharger");
          setSnackbar({ message, severity: "error" });
        }
      },
      [onChargerTemplateAddCallback]
    );

    const onFormSubmit = async (
      values: FieldValueTypes,
      actions: FormikHelpers<FieldValueTypes>
    ) => {
      const { connectors, evses, parameterSet } = values;
      const evseMap = new Map();
      evses.forEach((each, index) => {
        evseMap.set(index + 1, false);
      });
      connectors.forEach((connector) => {
        if (evseMap.has(connector.evseId)) {
          evseMap.set(connector.evseId, true);
        }
      });
      let ParamterSetParsed;
      ParamterSetParsed = JSON.parse(parameterSet);
      const allEvseHasConnectors = Array.from(evseMap.values()).every(Boolean);
      if (!allEvseHasConnectors) {
        actions.setStatus("Atleast one Connector is required for each Evse");
        return;
      } else {
        actions.setStatus("");
      }
      const finalValue = { ...values, parameterSet: ParamterSetParsed };
      if (vendorId && vendorModel) {
        createChargerTemplate(finalValue, vendorId, vendorModel);
      } else {
        createChargerTemplate(finalValue);
      }
    };

    return (
      <Slide in={modalOpen} direction="up" ref={ref} tabIndex={-1}>
        <Container
          disableGutters={true}
          component="main"
          maxWidth="md"
          sx={{
            outline: "none",
            position: "absolute",
            top: MODAL_SPACING,
            left: 0,
            right: 0,
            bottom: MODAL_SPACING,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              background: theme.palette.background.paper,
              color: theme.palette.getContrastText(
                theme.palette.background.paper
              ),
              boxShadow: theme.shadows[1],
              overflow: "hidden",
            }}
          >
            <Box>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ flex: 1 }} fontWeight={700} variant="h6">
                  {vendorId && vendorModel
                    ? "Edit Charger Template"
                    : "Add Charger Template"}
                </Typography>

                <IonIconButton
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon fill={"currentColor"} width={24} height={24} />
                </IonIconButton>
              </Box>
              <Stepper
                sx={{
                  px: 2,
                }}
                activeStep={activeStep}
                connector={
                  <StepConnector
                    sx={{
                      "& .MuiStepConnector-line": {
                        width: "80%",
                        margin: "auto",
                      },
                    }}
                  />
                }
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={handleStep(index)}>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box
              sx={{
                maxHeight: `calc(100vh - ${HEADER_HEIGHT + 2 * MODAL_SPACING}px)`,
                overflowY: "auto",
              }}
            >
              <Box>
                <Formik
                  initialValues={apiInitialValues}
                  validationSchema={validationSchema[activeStep]}
                  onSubmit={onFormSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    status,
                    isValid,
                    isSubmitting,
                    validateForm,
                    setTouched,
                  }) => {
                    const handleNext = async () => {
                      try {
                        const errors: FormikErrors<FormikValues> =
                          await validateForm();
                        if (Object.keys(errors).length === 0) {
                          console.log("working");
                          setActiveStep((prev) => prev + 1);
                        } else {
                          setTouched(errors);
                        }
                      } catch (err) {}
                    };
                    const evsesOptions = values.evses.map((item, index) => {
                      return { label: index + 1, value: index + 1 };
                    });
                    return (
                      <Form autoComplete="off" id="charger_template_Id">
                        <Grid container spacing={1} p={4}>
                          {activeStep === 0 &&
                            fields.map((item) => {
                              const { colCount, ...fieldProps } = item;
                              return (
                                <>
                                  <Grid
                                    xs={colCount}
                                    item
                                    key={fieldProps.name}
                                  >
                                    <Field
                                      {...fieldProps}
                                      disabled={
                                        !!vendorId &&
                                        !!vendorModel &&
                                        fieldProps.name !==
                                          fieldNames.vendorName
                                      }
                                    />
                                  </Grid>
                                </>
                              );
                            })}
                          {activeStep === 1 && (
                            <FieldArray name={fieldNames.evses}>
                              {({ push, remove }) => (
                                <>
                                  <Grid item xs={12}>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        mb: "1rem",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        fontSize={18}
                                        ml={-2}
                                      >
                                        EVSE
                                      </Typography>
                                      <IonButton
                                        variant="contained"
                                        color="button"
                                        onClick={() => {
                                          push({
                                            id: values.evses.length + 1,
                                            currentType: "",
                                            maxPower: 0,
                                            maxVoltage: 0,
                                            maxAmperage: 0,
                                          });
                                          if (
                                            values.evses.length >=
                                            values.connectors.length
                                          ) {
                                            values.connectors.push({
                                              id: values.connectors.length + 1,
                                              type: "",
                                              evseId: values.evses.length + 1,
                                              physicalReference: "",
                                              numberOfPhases: 0,
                                              maxPower: 0,
                                              maxVoltage: 0,
                                              maxAmperage: 0,
                                            });
                                          }
                                        }}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          "& .MuiButton-startIcon": {
                                            marginLeft: 0,
                                            marginRight: 0,
                                          },
                                        }}
                                        startIcon={
                                          <POIAdd
                                            fill="current"
                                            height={24}
                                            width={24}
                                          />
                                        }
                                      ></IonButton>
                                    </Box>
                                  </Grid>
                                  {values.evses.map((evseItem, index) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        gap: 4,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          mb: "1rem",
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          height: "24px",
                                          width: "24px",
                                          color: theme.palette.getContrastText(
                                            theme.palette.primary.main
                                          ),
                                          fontSize: "12px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "99px",
                                        }}
                                      >
                                        <span>{index + 1}</span>
                                      </Typography>
                                      <Grid
                                        container
                                        spacing={1}
                                        mb={4}
                                        key={evseItem.id}
                                      >
                                        {evseField.map((item) => {
                                          const { colCount, ...fieldProps } =
                                            item;
                                          return (
                                            <Grid
                                              xs={colCount}
                                              item
                                              key={`${fieldNames.evses}[${index}].${fieldProps.name}`}
                                            >
                                              <Field
                                                {...fieldProps}
                                                name={`${fieldNames.evses}[${index}].${fieldProps.name}`}
                                              />
                                            </Grid>
                                          );
                                        })}
                                        <Grid item xs={1} position={"relative"}>
                                          <Box
                                            sx={{
                                              position: "absolute",
                                              top: "-27px",
                                            }}
                                          >
                                            <Button
                                              onClick={() => {
                                                if (index >= 1) remove(index);
                                                for (
                                                  let i = index;
                                                  i < values.evses.length;
                                                  i++
                                                ) {
                                                  values.evses[i].id = i;
                                                }
                                              }}
                                              disabled={index === 0}
                                            >
                                              <DeleteIcon fill="currentColor" />
                                            </Button>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          )}
                          {activeStep === 1 && (
                            <FieldArray name={fieldNames.connectors}>
                              {({ push, remove }) => {
                                return (
                                  <>
                                    <Grid item xs={12}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                          mb: "1rem",
                                        }}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontSize={18}
                                          ml={-2}
                                        >
                                          Connectors
                                        </Typography>
                                        <IonButton
                                          variant="contained"
                                          color="button"
                                          sx={{
                                            whiteSpace: "nowrap",
                                            "& .MuiButton-startIcon": {
                                              marginLeft: 0,
                                              marginRight: 0,
                                            },
                                          }}
                                          startIcon={
                                            <POIAdd
                                              fill="current"
                                              height={24}
                                              width={24}
                                            />
                                          }
                                          onClick={() => {
                                            push({
                                              id: values.connectors.length + 1,
                                              evseId: values.evses.length,
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                    {values.connectors.map(
                                      (connector, index) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            gap: 4,
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              mb: "1rem",
                                              backgroundColor:
                                                theme.palette.primary.main,
                                              height: "24px",
                                              width: "24px",
                                              color:
                                                theme.palette.getContrastText(
                                                  theme.palette.primary.main
                                                ),
                                              fontSize: "12px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRadius: "99px",
                                            }}
                                          >
                                            <span>{index + 1}</span>
                                          </Typography>
                                          <Grid
                                            container
                                            mb={4}
                                            spacing={1}
                                            key={connector.id}
                                          >
                                            {connectorsField.map((item) => {
                                              const {
                                                colCount,
                                                ...fieldProps
                                              } = item;
                                              if (
                                                fieldProps.name ===
                                                connectorFieldNames.evseId
                                              ) {
                                                return (
                                                  <Grid
                                                    xs={colCount}
                                                    item
                                                    key={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                                                    mb={1}
                                                  >
                                                    <Field
                                                      {...fieldProps}
                                                      options={
                                                        evsesOptions || []
                                                      }
                                                      name={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                                                    />
                                                  </Grid>
                                                );
                                              }
                                              if (
                                                fieldProps.name ===
                                                connectorFieldNames.type
                                              ) {
                                                return (
                                                  <Grid
                                                    xs={colCount}
                                                    item
                                                    key={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                                                    mb={1}
                                                  >
                                                    <Field
                                                      {...fieldProps}
                                                      options={
                                                        allConnectors || []
                                                      }
                                                      name={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                                                    />
                                                  </Grid>
                                                );
                                              }
                                              return (
                                                <Grid
                                                  xs={colCount}
                                                  item
                                                  key={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                                                  mb={1}
                                                >
                                                  <Field
                                                    {...fieldProps}
                                                    name={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                                                  />
                                                </Grid>
                                              );
                                            })}
                                            <Grid
                                              xs={1}
                                              item
                                              position={"relative"}
                                            >
                                              <Box
                                                sx={{
                                                  position: "absolute",
                                                  top: "-27px",
                                                }}
                                              >
                                                <Button
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                  disabled={index === 0}
                                                >
                                                  <DeleteIcon fill="currentColor" />
                                                </Button>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )
                                    )}
                                  </>
                                );
                              }}
                            </FieldArray>
                          )}
                          {/* <FieldArray name={fieldNames.parameterSet}>
                          {({ push, remove }) => {
                            return (
                              <>
                                {values.parameterSet.map(
                                  (parameters, index) => {
                                    return (
                                      <>
                                        {parameterSetField.map((item) => {
                                          const { colCount, ...fieldProps } =
                                            item;
                                          return (
                                            <>
                                              <Grid
                                                item
                                                xs={colCount}
                                                key={fieldProps.name}
                                              >
                                                <Field
                                                  {...fieldProps}
                                                  name={`${fieldNames.parameterSet}[${index}]`}
                                                />
                                                <Button
                                                  onClick={() =>
                                                    push(values.parameterSet[0])
                                                  }
                                                >
                                                  Add ParameterSet
                                                </Button>
                                              </Grid>
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                )}
                              </>
                            );
                          }}
                        </FieldArray> */}
                          {/* <Divider sx={{ height: "10px", width: "100%" }} /> */}
                          {activeStep === 2 &&
                            parameterSetField.map((item) => {
                              const { colCount, ...fieldProps } = item;
                              return (
                                <>
                                  <Grid
                                    xs={colCount}
                                    item
                                    key={fieldProps.name}
                                  >
                                    <Field {...fieldProps} />
                                  </Grid>
                                </>
                              );
                            })}
                          <Grid item xs={6} />
                          <Grid container xs={6} spacing={2}>
                            <Grid item xs={3}>
                              <IonButton
                                type="button"
                                variant="outlined"
                                color="button"
                                fullWidth
                                disabled={activeStep === 0}
                                onClick={() => handleBack()}
                                sx={(theme) => ({
                                  height: 48,
                                  mt: 2,
                                })}
                                aria-label="Submit"
                              >
                                <LeftIcon />
                              </IonButton>
                            </Grid>
                            <Grid item xs={3}>
                              <IonButton
                                type="button"
                                variant="outlined"
                                color="button"
                                fullWidth
                                disabled={activeStep === 2}
                                onClick={() => handleNext()}
                                sx={(theme) => ({
                                  height: 48,
                                  mt: 2,
                                })}
                                aria-label="Submit"
                              >
                                <LeftIcon
                                  style={{
                                    transform: "rotate(180deg)",
                                  }}
                                />
                              </IonButton>
                            </Grid>

                            <Grid item xs={6}>
                              <IonButton
                                type="submit"
                                variant="contained"
                                color="button"
                                fullWidth
                                disabled={
                                  !isValid ||
                                  isSubmitting ||
                                  (!vendorId &&
                                    !vendorModel &&
                                    activeStep !== 2)
                                }
                                sx={(theme) => ({
                                  height: 48,
                                  mt: 2,
                                })}
                                aria-label="Submit"
                              >
                                {vendorId && vendorModel ? "Update" : "Add"}
                              </IonButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Paper>
          <IonSnackbar
            open={Boolean(snackbar.message && snackbar.severity)}
            onClose={() => setSnackbar({ message: "", severity: undefined })}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        </Container>
      </Slide>
    );
  }
);

export default ChargerTemplateModal;
