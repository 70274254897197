import IonTable from "@components/ionTable";
import IonageTooltip from "@components/ionTooltip";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import { getChargerTemplates } from "@nexusAPI/chargerTemplates";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as EditIcon } from "@assets/icons/pencil-icon.svg";
import { IonButton, IonButtonMultiVariant } from "@components/ionButton";
import ChargerTemplateModal from "./ChargerTemplateModal";
const TABLE_LIMIT = 50;

function ChargerTemplatesTable() {
  const theme = useTheme();
  const scrollParentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrolledIndex, setScrolledIndex] = useState<number>(0);
  const [chargertemplates, setChargerTemplates] = useState<
    Awaited<ReturnType<typeof getChargerTemplates>>
  >([]);

  const [updateChargerTemplateModal, setUpdateChargerTemplateModal] =
    useState<boolean>(false);

  const [vendorId, setVendorId] = useState<string>("");
  const [vendorModel, setVendorModel] = useState<string>("");
  const handleCloseChargerTemplateModal = () => {
    setUpdateChargerTemplateModal(false);
  };

  const resetTable = () => {
    setChargerTemplates([]);
    setScrolledIndex(0);
  };

  const onChargerTemplateAdd = (vendorId?: string, vendorModel?: string) => {
    if (vendorId && vendorModel) {
      setVendorId(vendorId);
      setVendorModel(vendorModel);
    } else {
      setVendorId("");
      setVendorModel("");
    }
    setUpdateChargerTemplateModal(true);
  };

  const onChargerTemplateAddCallback = () => {
    resetTable();
    getChargerTemplatesData();
    handleCloseChargerTemplateModal();
  };

  const getChargerTemplatesData = useCallback(async () => {
    try {
      const result3 = await getChargerTemplates();
      console.log(result3);
      setChargerTemplates(result3);
    } catch (err) {
      setChargerTemplates([
        {
          vendorId: "Servotech1",
          vendorModel: "ST-EVAC3KW1",
          vendorName: "Servotech pvt. ltd.",
          images: [],
          numberOfEvses: 1,
          numberOfConnectors: 2,
        },
      ]);
    }
  }, []);
  useEffect(() => {
    getChargerTemplatesData();
  }, [getChargerTemplatesData]);

  return (
    <>
      <Box sx={{ display: "flex", flex: 1 }}>
        <Box
          sx={{
            flex: 1,
            m: 2,
            display: "flex",
            flexDirection: "column",
            background: theme.palette.background.card,
            color: theme.palette.getContrastText(theme.palette.background.card),
            borderRadius: 2,
            overflow: "hidden",
            boxShadow: theme.shadows[1],
          }}
        >
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              py: 2,
              px: 2,
              backgroundColor: theme.palette.background.tableHead,
              color: theme.palette.getContrastText(
                theme.palette.background.tableHead
              ),
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          >
            <IonButton
              color={"button"}
              variant="contained"
              sx={{ px: 2, ml: 2, whiteSpace: "nowrap" }}
              startIcon={<POIAdd fill="current" height={24} width={24} />}
              onClick={() => onChargerTemplateAdd()}
            >
              Add Charger Template
            </IonButton>
          </Box>
          <Box sx={{ flex: 1, position: "relative" }}>
            <div
              ref={scrollParentRef}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: "auto",
              }}
            >
              <IonTable
                tableVirtuosoProps={{
                  useWindowScroll: true,
                  customScrollParent: scrollParentRef.current || undefined,
                  endReached: (index) => {
                    if ((index + 1) % TABLE_LIMIT === 0) {
                      setScrolledIndex(index + 1);
                    }
                  },
                }}
                tableProps={{
                  stickyHeader: true,
                  size: "small",
                }}
                columns={[
                  {
                    label: "Vendor ID",
                    key: "vendorId",
                    tooltip: "Comes from Boot Notification",
                  },
                  {
                    label: "Vendor model",
                    key: "vendorModel",
                    tooltip: "Comes from Boot Notification",
                  },
                  { label: "Vendor name", key: "vendorName" },
                  // { label: "Images", key: "images" },
                  { label: "Number of Evses", key: "numberOfEvses" },
                  { label: "Number of Connectors", key: "numberOfConnectors" },
                  {
                    label: "",
                    key: "actions",
                    width: "120px",
                  },
                ]}
                rows={chargertemplates.map((chargertemplate) => {
                  const {
                    vendorId,
                    vendorModel,
                    vendorName,
                    numberOfConnectors,
                    numberOfEvses,
                  } = chargertemplate;
                  return {
                    rowsx: {
                      "& .actions_button": {
                        visibility: "hidden",
                        opacity: 0,
                        transition:
                          "visibility 0.2s linear,opacity 0.2s linear",
                      },
                      "&:hover": {
                        "& .actions_button": {
                          visibility: "visible",
                          opacity: 1,
                        },
                      },
                    },
                    vendorId: (
                      <Box position={"relative"}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            inset: 0,
                          }}
                        >
                          <IonageTooltip
                            placement="bottom-start"
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 0],
                                  },
                                },
                              ],
                            }}
                            title={vendorId}
                          >
                            <Typography fontSize={14} noWrap={true}>
                              {vendorId}
                            </Typography>
                          </IonageTooltip>
                        </Box>
                      </Box>
                    ),
                    vendorModel: (
                      <Typography fontSize={14} noWrap={true}>
                        {vendorModel}
                      </Typography>
                    ),
                    vendorName: (
                      <Typography fontSize={14} noWrap={true}>
                        {vendorName}
                      </Typography>
                    ),
                    numberOfEvses: (
                      <Typography fontSize={14} noWrap={true}>
                        {numberOfEvses}
                      </Typography>
                    ),
                    numberOfConnectors: (
                      <Typography fontSize={14} noWrap={true}>
                        {numberOfConnectors}
                      </Typography>
                    ),
                    // images: <img src={images[0]} alt="" />,
                    actions: (
                      <Box
                        className="actions_button"
                        flex={1}
                        display={"flex"}
                        gap={"0.5rem"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <IonButtonMultiVariant
                          color="button"
                          onClick={() =>
                            onChargerTemplateAdd(vendorId, vendorModel)
                          }
                          sx={{
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            padding: 0,
                          }}
                        >
                          <EditIcon
                            width={16}
                            height={16}
                            fill="currentColor"
                          />
                        </IonButtonMultiVariant>
                      </Box>
                    ),
                  };
                })}
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Modal open={updateChargerTemplateModal}>
        <ChargerTemplateModal
          vendorId={vendorId || ""}
          vendorModel={vendorModel || ""}
          modalOpen={updateChargerTemplateModal}
          handleClose={handleCloseChargerTemplateModal}
          onChargerTemplateAddCallback={onChargerTemplateAddCallback}
        />
      </Modal>
    </>
  );
}

export default ChargerTemplatesTable;
